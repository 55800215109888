
import { reactive, defineComponent, ref, computed, } from "vue";
import ApiService from "@/core/services/ApiService";
import {
  showErrorPopup,
  showSuccessPopup,
} from "@/core/helpers/common-helper";
import useAccountType from "@/core/services/compositions/enum/useAccountType";
import useInstitution from "@/core/services/compositions/enum/useInstitution";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import moment from 'moment';
import { useRouter } from "vue-router";

interface Inquiry {
  account_number: string;
  account_holder: string;
  accountable_type: string;
  accountable_id: string;
  bank_code: string;
  bcn: string;
  description: string;
}

export default defineComponent({
  setup() {
    const submitButton = ref<null | HTMLButtonElement>(null);
    const saveButton = ref<null | HTMLButtonElement>(null);
    const inquirySuccess = ref(false);
    const router = useRouter();
    const dialogVisible = ref(false);
    const institutionName = ref('');
    const channelName = ref('');

    const { accountType } = useAccountType();

    const query = reactive({
      search: ''
    });

    const fetchParams = computed(() => ({
      name: query.search,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { institution, isValidating: isValidatingInstitution } = useInstitution(() => fetchParams.value);
    const { channel } = useChannelByHash();

    const inquiry = reactive<Inquiry>({
      account_number: '',
      account_holder: '',
      accountable_type: '',
      accountable_id: '',
      bank_code: '',
      bcn: '',
      description: '',
    });

    inquiry.bank_code = 'BMI';

    const submitInquiry = () => {

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        account_number: inquiry.account_number,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/account/inquiry`,
        payload as any
      )
        .then((response) => {
          inquirySuccess.value = true;
          inquiry.account_holder = response.data.data.beneficiaryAccountName
        })
        .catch(({ response }) => {
          inquiry.account_number = '';

          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const saveAccount = () => {
      dialogVisible.value = false;
      
      if (inquiry.accountable_type == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Account Type cannot empty',
          message: 'Account Type cannot empty',
        })

        return;
      }

      // if (inquiry.accountable_id == '') {
      //   showErrorPopup({
      //     title: 'Error',
      //     text: 'Institution/Channel cannot empty',
      //     message: 'Institution/Channel cannot empty',
      //   })

      //   return;
      // }

      if (inquiry.bcn == '' && inquiry.accountable_type == 'institution') {
        showErrorPopup({
          title: 'Error',
          text: 'BCN cannot empty if account type Institution',
          message: 'BCN cannot empty if account type Institution',
        })

        return;
      }

      saveButton.value ? (saveButton.value.disabled = true) : null;
      saveButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        account_number: inquiry.account_number,
        account_holder: inquiry.account_holder,
        accountable_type: inquiry.accountable_type,
        accountable_id: inquiry.accountable_id,
        bank_code: inquiry.bank_code,
        bcn: inquiry.bcn,
        description: inquiry.description,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/account`,
        payload as any
      )
        .then((response) => {
          showSuccessPopup({
            title: 'Save Account',
            text: 'Success'
          });

          setTimeout(() => {
            router.push({ name: "app.management.account" });
          }, 1700)

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          inquirySuccess.value = false;
          inquiry.account_number = '';
          inquiry.account_holder = '';
          inquiry.accountable_type = '';
          inquiry.accountable_id = '';
          inquiry.bcn = '';
          inquiry.description = '';

          saveButton.value ? (saveButton.value.disabled = false) : null;
          saveButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const changeAccountType = (val) => {
      inquiry.accountable_id = ''
      inquiry.bcn = ''
    }

    const changeInstitution = (val) => {
      institutionName.value = institution.value[val]
    }

    const changeChannel = (val) => {
      channelName.value = channel.value[val]
    }

    const clearInquiry = () => {
      inquirySuccess.value = false;
      inquiry.account_number = '';
      inquiry.account_holder = '';
      inquiry.accountable_type = '';
      inquiry.accountable_id = '';
      inquiry.bcn = '';
      inquiry.description = '';
    }

    const searchInstitution = (val) => {
      query.search = val;
    }

    const showDialog = () => {
      dialogVisible.value = true;
    }

    return {
      submitButton,
      inquiry,
      submitInquiry,
      inquirySuccess,
      saveButton,
      saveAccount,
      accountType,
      institution,
      channel,
      changeAccountType,
      clearInquiry,
      searchInstitution,
      isValidatingInstitution,
      dialogVisible,
      showDialog,
      changeInstitution,
      institutionName,
      channelName,
      changeChannel
    }
  }
});

